<template>
	<el-upload
		class="dataset-upload"
		v-bind="$attrs"
		:beforeUpload="onBeforeUpload"
		:on-error="onHandleError"
		:before-remove="handleBeforeRemove"
		:on-remove="handleRemove"
		:drag="drag"
		ref="uploadRef"
	>
		<template #default>
			<slot>
				<template v-if="drag">
					<el-icon class="el-icon--upload"><upload-filled /></el-icon>
					<div class="el-upload__text">
						<div>将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip">{{ props.tipText }}</div>
					</div>
				</template>
				<template v-else>
					<el-button type="primary">上传</el-button>
				</template>
			</slot>
		</template>
		<template #tip>
			<slot name="tip"></slot>
		</template>
		<template #file="{ file }">
			<slot name="file" :file="file">
				<div class="upload-item">
					<div class="file-item">
						<span class="file-name">{{ file.name }}</span>
						<span class="icon el-icon-delete delete-btn" style="position: relative; top: 2px; margin-left: 10px" @click="deleteFile(file)">
							<el-icon><delete-filled /></el-icon
						></span>
						<div class="file-pageno" v-if="showInput">
							<span>页码：</span>
							<el-input v-model="file.pageNo" size="small" placeholder="页码" @input="(val: unknown) => handleInput(val, file)"></el-input>
						</div>
					</div>
					<el-progress
						class="file-upload-progress"
						:percentage="file.percentage"
						v-if="showProgress && file.percentage < 100"
						:stroke-width="4"
						:status="fileStatus(file.status)"
					></el-progress>
				</div>
			</slot>
		</template>
	</el-upload>
</template>
<script lang="ts" setup>
import { PropType, defineProps, defineExpose, defineEmits, onMounted } from 'vue'
import { UploadRawFile, UploadFile, UploadFiles, ElUpload } from 'element-plus'
import { UploadFilled, DeleteFilled } from '@element-plus/icons-vue'
const props = defineProps({
	uploadRefs: {
		type: Array as PropType<InstanceType<typeof ElUpload>[]>,
		defualt: () => [],
	},
	tipText: {
		type: String,
		default: '只能上传图片文件',
	},
	// 上传文件前触发
	beforeUpload: {
		type: Function,
	},
	// 上传文件失败时触发
	onError: {
		type: Function,
	},
	// 移除前触发
	beforeRemove: {
		type: Function,
	},
	payload: {
		type: Object,
	},
	// 是否拖拽
	drag: {
		type: Boolean,
		default: false,
	},
	// 是否显示进度条
	showProgress: {
		type: Boolean,
		defualt: true,
	},
	// 是否显示输入框
	showInput: {
		type: Boolean,
		default: false,
	},
	// 上传文件展示的列数
	column: {
		type: Number,
		defualt: 1,
	},
})
const emits = defineEmits(['remove', 'input'])
const uploadRef = $ref<InstanceType<typeof ElUpload>>()
const uploadRefs = $ref(props.uploadRefs)
// 由于上传数据前可能需要传递额外的数据，所以在props中定义并手动处理一下
const onBeforeUpload = (input: UploadRawFile) => {
	if (props.beforeUpload) {
		props.beforeUpload(input, props.payload)
	}
}

// 上传失败时，有时需要获取额外的数据，所以在props中定义并手动处理一下
const onHandleError = (error: Error, uploadFile: UploadFile, uploadFiles: UploadFiles) => {
	if (props.onError) {
		props.onError(error, uploadFile, uploadFiles, props.payload)
	}
}

let isDeleteFile = $ref(false)
const handleBeforeRemove = (uploadFile: UploadFile, uploadFiles: UploadFiles) => {
	return isDeleteFile
}

const handleRemove = (uploadFile: UploadFile, uploadFiles: UploadFiles) => {
	isDeleteFile = false
}

const fileStatus = (status: string) => {
	if (status === 'success') {
		return 'success'
	}
	if (status === 'error') {
		return 'exception'
	}
	return null
}

const deleteFile = (uploadFile: UploadFile) => {
	isDeleteFile = true
	emits('remove', uploadFile, props.payload)
	uploadRef.handleRemove(uploadFile, uploadFile?.raw)
}

const handleInput = (val: unknown, file: UploadFile) => {
	emits('input', val, file)
}
const clearFiles = () => {
	uploadRef.clearFiles()
}

defineExpose({
	clearFiles,
})

onMounted(() => {
	uploadRefs.push(uploadRef)
})
</script>

<style lang="scss">
.dataset-upload {
	margin-left: 40px;
	.el-upload-dragger {
		width: 200px;
		height: 100px;
		padding: 18px;
	}
	.el-upload-dragger .el-icon--upload {
		font-size: 24px;
		margin: 0 auto;
	}
	.el-upload-dragger .el-upload__text {
		font-size: 12px;
	}
	// v-bind('props.column')
	.el-upload-list {
		display: grid;
		grid-template-columns: repeat(v-bind('props.column'), 1fr);
		column-gap: 40px;
	}
}
.upload-item {
	margin-bottom: 12px;
	.file-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.delete-btn {
		cursor: pointer;
		line-height: 30px;
	}
	.file-upload-progress {
		margin-top: 8px;
	}
	.file-pageno {
		float: right;
		margin-left: 24px;
		.el-input {
			width: 60px;
		}
	}
}
</style>
