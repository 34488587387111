const G = {
	isMock: false,
	ENV: import.meta.env.MODE,
	platform: import.meta.env.VITE_PLATFORM,
	defaultTitle: '算法数据中心',
	isKeepAlive: false,
}

export const DOMAIN_MAP = {
	domainName: 'VITE_BASE_URL',
	latex: import.meta.env.VITE_LATEX_API,
	epiboly: import.meta.env.VITE_EPIBOLY_URL,
	user: import.meta.env.VITE_USER_URL,
	question: import.meta.env.VITE_QUESTION_URL,
	validate: import.meta.env.VITE_VALIDATE_URL,
	fpdata: import.meta.env.VITE_FPDATA_URL,
	algodata: import.meta.env.VITE_ALGO_DATA_PLATFORM_URL,
	operation: import.meta.env.VITE_OPERATION_URL,
}

export const _USER_SERVER = {
	development: import.meta.env.VITE_BASE_URL,
	staging: import.meta.env.VITE_BASE_URL,
	production: import.meta.env.VITE_BASE_URL,
}
export default G
