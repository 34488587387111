import { VuexStateKeyG, VuexMutationKeyG } from '@kzfe/learnable-lib/package/vue3/framework/store/vuexEnum'

export class VuexStateKey extends VuexStateKeyG {
	/**枚举相关key */
	static serverAllEnum = 'serverAllEnum'
	static pageEnumList = 'pageEnumList'
	static homeworkAllEnum = 'homeworkAllEnum' // 作业平台相关枚举
}
export class VuexMutationKey extends VuexMutationKeyG {
	static updateServerAllEnum = 'updateServerAllEnum'
	/**枚举相关key */
	static updateHomeworkAllEnum = 'updateHomeworkAllEnum'
}
export class VuexActionKey {
	static queryAllEnum = 'queryAllEnum'
	static queryHomeworkAllEnum = 'queryHomeworkAllEnum'
}
export class VuexModuleKey {
	static enumModule = 'enumModule'
}
