import { App } from 'vue'
export default (app: App<Element>) => {
	app.directive('highlight', {
		// 这里的 el 就是被注册指令的 node 节点， binding 就是节点信息，
		// 包括一个 value，就是传进来的数据
		// vnode 就是 vue 虚拟节点
		//  刚刚注册这个事件，就使用 bind
		mounted(el: HTMLElement, binding) {
			const { value } = binding
			if (value && typeof value === 'object') {
				const { hWord, word } = value
				el.innerHTML = word.replace(new RegExp(hWord, 'ig'), (a: string) => {
					return `<span class="high-light">${a}</span>`
				})
			}
		},
		// 绑定的数据更新了，执行 update
		updated(el: HTMLElement, binding) {
			const { value } = binding
			if (value && typeof value === 'object') {
				const { hWord, word } = value
				el.innerHTML = word.replace(new RegExp(hWord, 'ig'), (a: string) => `<span class="high-light">${a}</span>`)
			}
		},
	})
}
