import { App } from 'vue'
import form from './form'
import table from './table'
import upload from './upload'
const components = [form, table, upload]

export default {
	install(app: App) {
		components.forEach(component => app.use(component))
	},
}
