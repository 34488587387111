import { VuexMutationKey, VuexStateKey, VuexActionKey } from '../vuexEnum'
import { AllTypeEnumList, FPDataEnumList, apiQueryAllEnum, apiQueryHomeworkAllEnum } from '@/api/enum'
import { Module } from 'vuex'
const enumModule: Module<
	{
		[x: string]: unknown
	},
	unknown
> = {
	namespaced: true,
	state: {
		[VuexStateKey.serverAllEnum]: {},
		[VuexStateKey.homeworkAllEnum]: {},
		[VuexStateKey.pageEnumList]: {
			ifContainCutEnumList: [
				{
					index: 1,
					desc: '切割成功',
				},
				{
					index: 2,
					desc: '切割失败',
				},
			],
			ifErrorRowEnumList: [
				{
					index: 1,
					desc: '无错误行',
				},
				{
					index: 2,
					desc: '有错误行',
				},
			],
			ifErrorCauseEnumList: [
				{
					index: 1,
					desc: '无错因',
				},
				{
					index: 2,
					desc: '有错因',
				},
			],
		},
	},
	mutations: {
		[VuexMutationKey.updateServerAllEnum](state, allEnum: AllTypeEnumList) {
			allEnum.consistencyRateTypeList = [
				{
					index: 0,
					desc: '符合预期',
					color: '#2ea121',
					parentIndex: null,
				},
				{
					index: 1,
					desc: '误识别',
					color: '#d83931',
					parentIndex: null,
				},
				{
					index: 2,
					desc: '漏识别',
					color: '#e6a23c',
					parentIndex: null,
				},
			]
			allEnum.isQuestionSubPositionEnumList = [
				{
					index: 0,
					desc: '不限',
					color: null,
					parentIndex: null,
				},
				{
					index: 1,
					desc: '是',
					color: null,
					parentIndex: null,
				},
				{
					index: 2,
					desc: '否',
					color: null,
					parentIndex: null,
				},
				{
					index: 3,
					desc: '无法判断',
					color: null,
					parentIndex: null,
				},
			]
			state[VuexStateKey.serverAllEnum] = allEnum
		},
		[VuexMutationKey.updateHomeworkAllEnum](state, allEnum: FPDataEnumList) {
			state[VuexStateKey.homeworkAllEnum] = allEnum
		},
	},
	actions: {
		async [VuexActionKey.queryAllEnum]({ commit }) {
			const allEnum = await apiQueryAllEnum()
			commit(VuexMutationKey.updateServerAllEnum, allEnum)
		},
		async [VuexActionKey.queryHomeworkAllEnum]({ commit }) {
			const allEnum = await apiQueryHomeworkAllEnum()
			commit(VuexMutationKey.updateHomeworkAllEnum, allEnum)
		},
	},
}

export default enumModule
