/**
 * 枚举相关api
 */
import http from '@kzfe/learnable-lib/package/vue3/framework/utils/http'
import { cloneDeep } from 'lodash'
import G from '@/config/config'
import { EnvName } from '@/config/NodeEnv'
import store from '@/store'
import { VuexModuleKey, VuexStateKey } from '@/store/vuexEnum'
export interface EnumItem {
	index: number | null
	desc: string
	color: string | null
	parentIndex: string | null
}

// 所有枚举
export interface AllTypeEnumList {
	againOcrStatusEnumList: EnumItem[]
	gradingExceptionEnumList: EnumItem[]
	gradingTestResultOcrStatusEnumList: EnumItem[]
	gradingTestSetDataStatusEnumList: EnumItem[]
	gradingTestSetDataTypeEnumList: EnumItem[]
	gradingTestTaskStatusEnumList: EnumItem[]
	hasFeedbackEnumList: EnumItem[]
	ocrExceptionEnumList: EnumItem[]
	pageImageOcrStatusEnumList: EnumItem[]
	pageImageStatusEnumList: EnumItem[]
	queryUniformityEnumList: EnumItem[]
	resultUniformityEnumList: EnumItem[]
	reviewQuestionTypeEnumList: EnumItem[]
	oldReviewQuestionTypeEnumList: EnumItem[]
	subjectEnumList: EnumItem[]
	testTaskTypeEnumList: EnumItem[]
	validityEnumList: EnumItem[]
	queryValidityEnumList: EnumItem[]
	teachingVersionEnumList: EnumItem[]
	termEnumList: EnumItem[]
	gradingTestResultStatusEnumList: EnumItem[]
	mailStatusEnumList: EnumItem[]
	gradingTestResultTypeResultEnumList: EnumItem[]
	reviewQuestionTypeEnum: EnumItem[]
	gradingAndOrcExceptionEnumList: EnumItem[]
	checkValidityEnumList: EnumItem[]
	artificialCheckValidityEnumLit: EnumItem[]
	reviewStatusEnum: EnumItem[]
	gradingTestSetFullImageType: EnumItem[]
	questionModeEnumList: EnumItem[]

	consistencyRateTypeList: EnumItem[]
	reviewRecordUpgradeExceptionList: EnumItem[] // 异常枚举
	exerciseBookTestTaskStatusEnumList: EnumItem[] // 验证状态枚举
	// sprint25 新增
	ocrEnvEnumList: EnumItem[] // ocr环境枚举
	graderEnvEnumList: EnumItem[] // grader环境枚举
	rejectionEvaluationTaskStatusEnumList: EnumItem[] // 拒识评测任务状态枚举
	rejectionEvaluationStatusEnumList: EnumItem[] // 拒识状态枚举
	rejectionEvaluationEnvironmentEnumList: EnumItem[] // 拒识评测环境枚举
	// sprint26 新增
	isQuestionSubPositionEnumList: EnumItem[] // 是否有题目子位置枚举
	taskFeedbackStatusEnumList: EnumItem[] // 任务反馈状态枚举
	rejectionCodeEnumList: EnumItem[] // 拒识分类枚举
	validateMode: EnumItem[] // 验证模式

	openGradingQuestionType: EnumItem[] // 回流题型
	answerSources: EnumItem[]
	openValidityEnumList: EnumItem[]
}

export interface FPDataEnumList {
	homeworkTypeList: EnumItem[] // 作业类型枚举
	reviewAcceptTypeList: EnumItem[]
	// sprint33
	algorithmMistakeEnumList: EnumItem[] // 算法错误枚举
}

export interface PageEnumList {
	ifContainCutEnumList: EnumItem[]
}

export type EnumKey = keyof AllTypeEnumList
// 获取学校相关类型枚举
export function apiQueryAllEnum() {
	return http<AllTypeEnumList>(
		'GET',
		'/enum/query',
		{},
		{
			timeout: 300000,
			domainName: 'validate',
		}
	)
}

export function apiQueryHomeworkAllEnum() {
	return http<FPDataEnumList>(
		'GET',
		'/enum/query',
		{},
		{
			timeout: 300000,
			domainName: 'fpdata',
		}
	)
}

// 学科枚举
export interface SubjectEnumList {
	subjectGroupList: SubjectEnumItem[]
	subjectList: SubjectEnumItem[]
}
export interface SubjectEnumItem {
	index?: number
	type: number
	name: string
	detailName?: string
}

export interface EnumColorItem {
	value: number
	label: string
	color: string
}

const DEFAULT_SERVER_ENUM: EnumItem = {
	index: -1,
	desc: '',
	color: null,
	parentIndex: null,
}

// 根据index获取对应的枚举的属性(desc、color, parentIndex)
export function getServerEnumObjByCode(listName: EnumKey, index: number, prop: keyof EnumItem = 'desc'): string {
	return getEnumObjByCode(listName, index, prop, false)
}

export function getHomeowrkServerEnumObjByCode(listName: EnumKey, index: number, prop: keyof EnumItem = 'desc'): string {
	return getEnumObjByCode(listName, index, prop, true)
}

function getEnumObjByCode(listName: EnumKey, index: number, prop: keyof EnumItem = 'desc', isHomeowrkServer = false) {
	const resultDefaultEnum = cloneDeep(DEFAULT_SERVER_ENUM) as EnumItem
	const envIsPro = G.ENV === EnvName.PRODUCTION
	const enumModule = store.state[VuexModuleKey.enumModule] as unknown as {
		[key: string]: unknown
	}
	const enums = enumModule[isHomeowrkServer ? VuexStateKey.homeworkAllEnum : VuexStateKey.serverAllEnum] as unknown as AllTypeEnumList
	const list = enums[listName]
	if (!list) {
		resultDefaultEnum.desc = `getServerEnumObjByCode: can't find list array.`
		return envIsPro ? (DEFAULT_SERVER_ENUM[prop] as string) : '-'
	}
	const result = list.find(item => item.index == index)
	if (result) {
		resultDefaultEnum.index = index
		return result[prop] as string
	} else {
		resultDefaultEnum.desc = `code 【${index}】 can't find in ${JSON.stringify(list)}`
		return '-'
	}
}
