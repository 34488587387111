import { App } from 'vue'
interface DataItem {
	[index: string]: unknown
}
export default (app: App<Element>) => {
	app.directive('disableOption', {
		// 绑定的数据更新了，执行 update
		updated(el: HTMLElement, binding) {
			// values v-model 绑定值
			// options 下拉选项
			// prop 对应 options 中 的 value 属性
			// defaultProp 默认值判断属性
			// defaultValue 默认值判断值
			const [values, options, prop, idsArr] = binding.value
			if (!values) {
				return
			}
			// 需要隐藏的标签索引
			const indexs = [] as number[]
			const tempData = values.map((a: unknown) => options.find((op: DataItem) => op[prop] == a)) as DataItem[]
			tempData.forEach((a, index) => {
				if (!a) {
					return
				}
				if (idsArr.includes(a[prop])) indexs.push(index)
			})
			const dealStyle = function (tags: NodeListOf<Element>) {
				tags.forEach((el, index) => {
					if (indexs.includes(index) && ![...el.classList].includes('select-tag-close-none')) {
						el.classList.add('select-tag-none')
					}
				})
			}
			// 设置样式 隐藏close icon
			const tags = el.querySelectorAll('.el-tag__close')
			if (tags.length === 0) {
				// 初始化tags为空处理
				setTimeout(() => {
					const tagTemp = el.querySelectorAll('.el-tag__close')
					dealStyle(tagTemp)
				})
			} else {
				dealStyle(tags)
			}
		},
	})
}
