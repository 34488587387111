import { createStore } from 'vuex'
import { VuexModuleKey } from './vuexEnum'
import { frameworkState, frameworkMutations } from '@kzfe/learnable-lib/package/vue3/framework/store/index'
import enumModule from './modules/enum'

const store = createStore({
	state: {
		...frameworkState,
	},
	mutations: {
		...frameworkMutations,
	},
	actions: {},
	modules: { [VuexModuleKey.enumModule]: enumModule },
})

export default store
